import { useEffect, useState } from 'react'
import { InitResult, init } from './init'

export function useInit() {
  const [initResult, setInitResult] = useState<InitResult | undefined>()

  useEffect(() => {
    ;(async () => {
      setInitResult(await init())
    })()
  }, [])

  return initResult
}
