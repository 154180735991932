import { memo } from 'react'
import { Spinner } from '../spinner'
import { OverlayModal } from './overlay-modal'

type Props = { visible: boolean }

export const OverlaySpinner = memo<Props>(({ visible }) => {
  if (!visible) return null

  return (
    <OverlayModal>
      <Spinner />
    </OverlayModal>
  )
})

OverlaySpinner.displayName = 'OverlaySpinner'
